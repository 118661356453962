<template>
  <div style="text-align: center;padding-top: 200px;opacity: 0.55;">
    <img style="width:60px;" src="../../assets/images/empty.png"/>
    <p></p>
    <p>功能暂不可用</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
